html, body
  margin 0
  padding 0
  font-size 14px
  color color_black
  font-family  Pretendard, AppleSDGothicNeo, -apple-system, BlinkMacSystemFont, "Droid Sans", "Roboto", "Segoe UI", "Helvetica", Arial, sans-serif
  font-weight 400
  .is_kr[lang="en"]
  .is_kr[lang="jp"]
    display none !important
a:where(:not(.twp *)):where(:not([data-headlessui-state]))
  &:visited
  &:hover
  &:active
  &:link
    color inherit
    text-decoration none

html[lang="jp"], html[lang="jp"] body
  letter-spacing 0.5px
  *
    word-break break-all !important

html[lang="kr"]
html[lang="kr"] body
  letter-spacing -0.1px

html, body
  button
  textarea
    font-size 14px
    font-weight 400
    font-family Pretendard, AppleSDGothicNeo, -apple-system, BlinkMacSystemFont, "Droid Sans", "Roboto", "Segoe UI", "Helvetica", Arial, sans-serif
    touch-action manipulation
  img:not([src])
    visibility hidden
  .don_frame
    z-index 3
  .don_frame[frame_index="0"]
    z-index 1
    >.don_wrapper
      >.header
        display none

html:not(.membership-sdk-global-style-identifier), body:not(.membership-sdk-global-style-identifier)
  button
    cursor pointer
  img
    border 0

html:not(.creator)
html:not(.creator) body
  @media screen and (min-width 1025px)
    position relative
    min-width 1280px

html, body
  &.fixed
    position fixed !important
    overflow-y visible !important
    top 0
    left 0
    right 0
    bottom 0

  #not_found_results
    display none
    text-align center
    &:before
      content ''
      width 80px
      height 80px
      display block
      margin 0 auto 24px
      background-image url(//s3.marpple.co/files/u_29089/2018/11/original/f_12439_1541417929892_ee1z2Lp6ee7s1SzW4E9i3G4D.png)
      background-size 80px 80px
    @media screen and (min-width 1025px)
      margin 216px auto
      font-size 16px
    @media screen and (max-width 1024px)
      margin 145px auto
  .countries
    .selected
      text-align left !important
      &:after
        margin 5px 0 0 41px
        content ''
        display inline-block
        vertical-align top
        background-image url(//s3.marpple.co/files/u_29089/2018/11/original/f_12433_1541404850026_iEeinTE5CJn0d6Awz1T.png)
        background-size 10px 6px
        width 10px
        height 6px
    ul
      list-style none
      padding 0
      margin 0
      display none
    span.flag
      display inline-block
      vertical-align top
      width 18px
      height 18px
      margin-right 8px
      background-size 18px 18px
      &.jp
        background-image url(//s3.marpple.co/files/u_218933/2019/11/original/781721dfbb977155adc496d5846f30d61cf50306fe1316.png)
      &.en
        background-image url(//s3.marpple.co/files/u_29089/2018/12/original/f_12659_1543999542518_I0ER4Mo8cCTZO3E8Ug2qe.png)
      &.kr
        background-image url(//s3.marpple.co/files/u_29089/2018/12/original/f_12660_1543999629033_xfxTzn6B4IafTdl3d7q.png)

  @media screen and (min-width 1025px)
    .mobile_v
      display none !important
    .store-body
      #error_404
        padding 300px 0 160px
    #error_404
      position relative
      margin 0 auto
      padding 300px 0
      display table
      text-align center
      h1
        font-size 50px
      h3
        font-size 30px
      p
        text-align center
        a
          margin-top 20px
          display inline-block
          border 1px solid #d5dbe0
          padding 16px 24px
          font-size 20px

  @media screen and (max-width 1024px)
    & body
      -webkit-tap-highlight-color transparent
      button
      a
        -webkit-tap-highlight-color initial

    .pc_v
      display none !important
    .store-body
      #error_404
        padding 100px 16px 10px
    #error_404
      text-align center
      position relative
      padding 150px 16px
      h1
        font-size 20px
      h3
        font-size 14px
      p
        span
          margin 0 10px
        a
          font-size 12px
          margin-top 20px
          display inline-block
          border 1px solid #d5dbe0
          padding 8px 12px

.navigation.home
  .sell_family
    display none !important

.live_chat
  cursor pointer

#ch-plugin-core
  > div[data-ch-testid]
    z-index 1
    transition transform 0.3s, opacity 0.3s, z-index 0s 0.5s
    transform translate3d(0, 0, 0)
  div[class^=Launcherstyled__TextLauncher-]
    padding-bottom env(safe-area-inset-bottom) !important
    padding-bottom constant(safe-area-inset-bottom) !important

html:not([f_index="0"]) #ch-plugin-core
#ch-plugin-core.hide
  > div[data-ch-testid]
    opacity 0
    transform scale(0.8)
    z-index -1 !important

@media screen and (max-width 1024px)
  html.product.detail
    #ch-plugin-core
      > div[data-ch-testid]
        margin-bottom 48px

html body.ch-plugin-mobile
  position: inherit !important;
  width: inherit !important;
  height: inherit !important;
  overflow: inherit !important;

.mp_currency_kr:after
  content '원'
.mp_currency_kr2:before
  content '￦'
.mp_currency_en:before
  content '$'
.mp_currency_jp:before
  content '¥'

html[lang="kr"]
  .mp_currency:after
    content '원'
  .mp_currency2:before
    content '￦'
    margin-right 4px
  .mp_currency_minus:after
    content '원'
  .mp_currency_minus:before
    content '- '
  div[lang="en"]
  div[lang="null"]
    .mp_currency:after
    .mp_currency2:after
      content ''
    .mp_currency_minus:after
      content ''
    .mp_currency_minus:before
      content '- $'
    .mp_currency:before
    .mp_currency2:before
      content '$'
  div[lang="jp"]
    .mp_currency:after
    .mp_currency2:after
      content ''
    .mp_currency_minus:after
      content ''
    .mp_currency_minus:before
      content '- ¥'
    .mp_currency:before
    .mp_currency2:before
      content '¥'

html[lang="en"]
html[lang="null"]
  .mp_currency:before
  .mp_currency2:before
    content '$'
  .mp_currency_minus:before
    content '- $'
  .old_message
    display none
  div[lang="kr"]
    .mp_currency:before
    .mp_currency2:before
      content '￦'
    .mp_currency_minus:before
      content '- ￦'
  div[lang="jp"]
    .mp_currency:after
    .mp_currency2:after
      content ''
    .mp_currency_minus:after
      content ''
    .mp_currency_minus:before
      content '- ¥'
    .mp_currency:before
    .mp_currency2:before
      content '¥'
html[lang="jp"]
  .mp_currency:before
  .mp_currency2:before
    content '¥'
  .mp_currency_minus:before
    content '- ¥'
  .old_message
    display none
  div[lang="kr"]
    .mp_currency:before
    .mp_currency2:before
      content '￦'
    .mp_currency_minus:before
      content '- ￦'
  div[lang="en"]
  div[lang="null"]
    .mp_currency:after
    .mp_currency2:after
      content ''
    .mp_currency_minus:after
      content ''
    .mp_currency_minus:before
      content '- $'
    .mp_currency:before
    .mp_currency2:before
      content '$'
.front_exclamation_mark
  padding-left 19px
  background url(//s3.marpple.co/files/u_193535/2020/1/original/1424941f58067e04ea486b0589f62b5de2606e134a20cbb.png) no-repeat 0 3px
  background-size 14px
span.mp_input
  input
    display none
    &:checked + span
      &:after
        position absolute
        content ""
        width 8px
        height 8px
        background color_active
        border-radius 50%
        top 4px
        left 4px
  span
    box-sizing border-box
    width 18px
    height 18px
    border 1px solid #d5dbe0
    border-radius 50%
    position relative
    display inline-block
    vertical-align bottom
    margin-right 8px
  input[type="checkbox"] + span
    border 2px solid #dcdcdc
    border-radius 2px
    width 18px
    height 18px
  input[type="checkbox"]
    &:checked + span
      border 2px solid transparent
      &:after
        position absolute
        content ""
        width 18px
        height 18px
        border-radius 0
        background url(//s3.marpple.co/files/u_193535/2020/1/original/1424850900fd6d4aa408bb688a5d3afe1a213e45289beec.png) no-repeat 0 0
        background-size 18px
        top -2px
        left -2px


html[is_ie="true"]
  #fake_channel_io
    position fixed !important
    z-index 10
    bottom 10px
    right 10px
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 6px 0px !important;
    box-sizing: border-box !important;
    cursor: pointer !important;
    flex-wrap: nowrap !important;
    width: 62px !important;
    height: 62px !important;
    overflow: initial !important;
    background: linear-gradient(rgb(4, 4, 4) 0%, rgb(4, 4, 4) 50%, rgb(4, 4, 4) 100%) !important;
    border-radius: 31px !important;
    &:before
      position absolute
      top 0px
      left 0px
      right 0
      bottom 0
      margin auto
      content: "" !important;
      display: block !important;
      width: 34px !important;
      height: 34px !important;
      background-image: url(https://cdn.channel.io/plugin/images/ch-new-launcher-icon-68.png) !important;
      background-size: 136px !important;
      background-position-x: 0px !important;
      background-position-y: 0px !important;

html[collabo_type="line"]
  #fake_channel_io
    background: linear-gradient(rgb(0, 209, 99) 0%, rgb(0, 209, 99) 50%, rgb(0, 209, 148) 100%) !important;

@media screen and (min-width 1025px)
  #my_menu_layer
    position absolute
    z-index 100
    .menu
      width 280px
      min-height 114px
      z-index 2
      padding 0 0 15px
      background-color #fff
      box-shadow 0 2px 16px 2px rgba(0, 0, 0, 0.1)
      position absolute
      left -230px
      margin 0 auto
      .arrow_up
        position absolute
        top -14px
        left 220px
        width 0
        height 0
        border-left 10px solid transparent
        border-right 10px solid transparent
        border-bottom 14px solid #fff
      .email
        padding 30px 24px 10px
        font-size 14px
        color color_gray3
      ul
        margin 0
        padding 0 24px
        list-style none
        li
          border-top 1px solid #d5dbe0
          padding 15px 0 14px
          html[collabo_type="line"] &.my_point
          html[collabo_type="line"] &.old_message
            display none
          &:first-child
            border-top 0 none
          a
            font-size 14px
            color color_black


html.open-editor[is_mobile="true"]
  #ch-plugin
    display none !important

